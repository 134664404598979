import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withFormik } from "formik";
import { compose } from "recompose";
import PhonePrefix from "../../components/inputs/PhonePrefix";
import DayOptionComponent from "../../components/inputs/DayOptionComponent";
import MonthOptionComponent from "../../components/inputs/MonthOptionComponent";
import YearOptionComponent from "../../components/inputs/YearOptionComponent";
import { COUNTRIES } from "../../config/data/v3countries";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  GET_REGIONS,
  GET_CITIES,
  GET_TOWNSHIPS,
} from "../../queries/countryquery";
import {
  DivisionList,
  OneList,
  TwoList,
  ThreeList,
  FourList,
  FiveList,
  SixList,
  SevenList,
  EightList,
  NineList,
  TenList,
  ElevenList,
  TwelveList,
  ThirteenList,
  FourteenList,
  CardList,
} from "../../config/data/nrcList";
import {
  MYANMAR_ID,
  YANGON_REGION,
  YANGON_CITY,
  IDENTIFIER,
  LOGIN_VIA_KBZPAY,
  LOGIN_PHONE,
} from "../../config/constants";
import "../../assets/scss/Home.scss";
import OTP_LOGIN_EMAIL_REGEX from "../../config/regex/otpLoginEmailRegex";
import ValidationMethod from "./ValidationMethod";
import ProfileEditForm from "./ProfileEditForm";
import { useTranslation } from "react-i18next";
import MediaQuery from "react-responsive";

// eslint-disable-next-line no-unused-vars
let socialLogin = false;
let pageName = "home";

const SignupForm = ({
  errorMsg,
  isDim,
  values,
  page,
  isSpinner,
  handleSubmit,
  setFieldValue,
  touched,
  errors,
  formError,
  setFormError,
  regValues,
  isSocialLogin,
  socialLoginType,
  val,
  data,
  addId,
  campaignId,
  setIsShow,
}) => {
  const [selectedDivision, setSelectedDivison] = useState("1");
  const [selectedList, setSelectedList] = useState("HsaBaTa");
  const [selectedCardList, setSelectedCardList] = useState("N");
  const [selectedNrcNo, setSelectedNrcNo] = useState("");
  const [listArr, setListArr] = useState(OneList);
  const [regionArr, setRegionArr] = useState([]);
  const [cityArr, setCityArr] = useState([]);
  const [townshipArr, setTownshipArr] = useState([]);
  const [flag, setFlag] = useState(false);
  const [date] = useState(false);
  const [checkNRC, setCheckNRC] = useState(false);
  const [isSubmitClick, setIsSubmitClick] = useState(false);
  const { t } = useTranslation();

  socialLogin = isSocialLogin;

  pageName = page;

  const [getRegion] = useLazyQuery(GET_REGIONS, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      let arr =
        res.regions.data.length > 0
          ? res.regions.data.map((option) => ({
              value: option.id,
              label: option.name,
            }))
          : [];
      setRegionArr(arr);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [getCity] = useLazyQuery(GET_CITIES, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      let arr =
        res.cities.data.length > 0
          ? res.cities.data.map((option) => ({
              value: option.id,
              label: option.name,
            }))
          : [];
      setCityArr(arr);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [getTownship] = useLazyQuery(GET_TOWNSHIPS, {
    fetchPolicy: "network-only",
    onCompleted: (res) => {
      let arr =
        res.townships.data.length > 0
          ? res.townships.data.map((option) => ({
              value: option.id,
              label: option.name,
            }))
          : [];
      setTownshipArr(arr);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const chooseList = (selected) => {
    switch (selected) {
      case "1":
        return setListArr(OneList);
      case "2":
        return setListArr(TwoList);
      case "3":
        return setListArr(ThreeList);
      case "4":
        return setListArr(FourList);
      case "5":
        return setListArr(FiveList);
      case "6":
        return setListArr(SixList);
      case "7":
        return setListArr(SevenList);
      case "8":
        return setListArr(EightList);
      case "9":
        return setListArr(NineList);
      case "10":
        return setListArr(TenList);
      case "11":
        return setListArr(ElevenList);
      case "12":
        return setListArr(TwelveList);
      case "13":
        return setListArr(ThirteenList);
      case "14":
        return setListArr(FourteenList);
      default:
        return setListArr(OneList);
    }
  };

  useEffect(() => {
    if (!flag) {
      let val =
        regValues && regValues.adultInfo && regValues.adultInfo.length > 0
          ? regValues.adultInfo[0]
          : null;

      getRegion({
        variables: {
          first: 30,
          page: 1,
          filter: [
            {
              field: "country.id",
              operator: "=",
              value: String(MYANMAR_ID),
            },
          ],
        },
      });
      getCity({
        variables: {
          first: 30,
          page: 1,
          filter: [
            {
              field: "region_id",
              operator: "=",
              value:
                val && val.regionId
                  ? String(val.regionId)
                  : String(YANGON_REGION),
            },
          ],
        },
      });
      getTownship({
        variables: {
          first: 30,
          page: 1,
          filter: [
            {
              field: "city_id",
              operator: "=",
              value:
                val && val.cityId ? String(val.cityId) : String(YANGON_CITY),
            },
          ],
        },
      });

      let isCheck =
        values.signupNrc !== "" && values.signupNrc.indexOf("/") !== -1
          ? true
          : false;
      if (isCheck) {
        chooseList(values.signupNrc.split("/")[0]);
        setSelectedDivison(values.signupNrc.split("/")[0]);
        setSelectedList(
          values.signupNrc.substring(
            values.signupNrc.indexOf("/") + 1,
            values.signupNrc.lastIndexOf("("),
          ),
        );
        setSelectedCardList(
          values.signupNrc.substring(
            values.signupNrc.indexOf("(") + 1,
            values.signupNrc.lastIndexOf(")"),
          ),
        );
        setSelectedNrcNo(values.signupNrc.split(")")[1]);
      }

      setFlag(true);
    }
  }, [
    flag,
    getCity,
    getRegion,
    getTownship,
    regValues,
    values,
    values.nrc,
    values.signupNrcPass,
    values.singupNrc,
  ]);

  const setDefaultList = (selected) => {
    switch (selected) {
      case "1":
        setSelectedList("HsaBaTa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "HsaBaTa", "N", "");
        break;
      case "2":
        setSelectedList("BaLaKha");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "BaLaKha", "N", "");
        break;
      case "3":
        setSelectedList("RaRaTha");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "RaRaTha", "N", "");
        break;
      case "4":
        setSelectedList("HsaMaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "HsaMaNa", "N", "");
        break;
      case "5":
        setSelectedList("KhaPaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "KhaPaNa", "N", "");
        break;
      case "6":
        setSelectedList("KaLaA");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "KaLaA", "N", "");
        break;
      case "7":
        setSelectedList("AhPhaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "AhPhaNa", "N", "");
        break;
      case "8":
        setSelectedList("MaHtaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "MaHtaNa", "N", "");
        break;
      case "9":
        setSelectedList("NgaZaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "NgaZaNa", "N", "");
        break;
      case "10":
        setSelectedList("KhaZaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "KhaZaNa", "N", "");
        break;
      case "11":
        setSelectedList("TaPaWa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "TaPaWa", "N", "");
        break;
      case "12":
        setSelectedList("AhLaNa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "AhLaNa", "N", "");
        break;
      case "13":
        setSelectedList("KaTaLa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "KaTaLa", "N", "");
        break;
      case "14":
        setSelectedList("KaKaHTa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "KaKaHTa", "N", "");
        break;
      default:
        setSelectedList("HsaBaTa");
        setSelectedCardList("N");
        setSelectedNrcNo("");
        handleNrc(selected, "HsaBaTa", "N", "");
        break;
    }
  };

  const handleNrc = (division, list, cardList, nrcNo) => {
    setFieldValue(
      "signupNrc",
      division + "/" + list + "(" + cardList + ")" + nrcNo,
    );
  };

  useEffect(() => {
    if (val) {
      setFieldValue("signupPrefix", val?.gender);
      setFieldValue("signupFirstName", val?.firstName);
      setFieldValue("signupLastName", val?.lastName);
      setFieldValue("signupEmail", val?.email);
      setFieldValue("signupPhone", val?.mobileNo);
      setFieldValue("signupPhonePrefix", val?.mobilePrefix);
    }
  }, [setFieldValue, val]);

  useEffect(() => {
    if (data) {
      setFieldValue(
        "signupPrefix",
        data?.prefix ? data.prefix.replace(".", "") : "Mr",
      );
      setFieldValue("signupFirstName", data?.first_name);
      setFieldValue("signupLastName", data?.last_name);
      setFieldValue("signupEmail", data?.email);
      setFieldValue(
        "signupPhonePrefix",
        data?.phone?.replace(" ", "")?.substring(0, 2),
      );
      setFieldValue("signupPhone", data?.phone?.replace(" ", "")?.substring(2));
      setFieldValue("signupDobDay", data?.dob?.split("-")[2]);
      setFieldValue("signupDobMonth", data?.dob?.split("-")[1]);
      setFieldValue("signupDobYear", data?.dob?.split("-")[0]);
      setFieldValue("signupNationality", data?.nationality?.id);
      setFieldValue("signupNrcPass", data?.passport);
      setFieldValue("signupExpDay", data?.passport_exp?.split("-")[2]);
      setFieldValue("signupExpMonth", data?.passport_exp?.split("-")[1]);
      setFieldValue("signupExpYear", data?.passport_exp?.split("-")[0]);
      setFieldValue("address", data?.address);
      // setSelectedDivison(data?.nrc?.split("/")[0]);
      // chooseList(data?.nrc?.split("/")[0]);
      // setSelectedList(
      //   data?.nrc?.substring(
      //     data?.nrc?.indexOf("/") + 1,
      //     data?.nrc?.lastIndexOf("("),
      //   ),
      // );
      // setSelectedCardList(
      //   data?.nrc?.substring(
      //     data?.nrc?.indexOf("(") + 1,
      //     data?.nrc?.lastIndexOf(")"),
      //   ),
      // );
      // setSelectedNrcNo(
      //   data?.nrc?.split(")")[1] !== "undefined"
      //     ? data?.nrc?.split(")")[1]
      //     : "",
      // );

      setFieldValue("township", data?.township?.id);
      setFieldValue("city", data?.township?.city?.id);
      setFieldValue(
        "signupNrc",
        data?.nrc?.split("/")[0] +
          "/" +
          data?.nrc?.substring(
            data?.nrc?.indexOf("/") + 1,
            data?.nrc?.lastIndexOf("("),
          ) +
          "(" +
          data?.nrc?.substring(
            data?.nrc?.indexOf("(") + 1,
            data?.nrc?.lastIndexOf(")"),
          ) +
          ")" +
          data?.nrc?.split(")")[1],
      );
      setSelectedDivison(data?.nrc?.split("/")[0]);
      chooseList(data?.nrc?.split("/")[0]);
      setSelectedList(
        data?.nrc?.substring(
          data?.nrc?.indexOf("/") + 1,
          data?.nrc?.lastIndexOf("("),
        ),
      );
      setSelectedCardList(
        data?.nrc?.substring(
          data?.nrc?.indexOf("(") + 1,
          data?.nrc?.lastIndexOf(")"),
        ),
      );
      setSelectedNrcNo(
        data?.nrc?.split(")")[1] !== "undefined"
          ? data?.nrc?.split(")")[1]
          : "",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (page === "home") {
      setFieldValue(
        "signupEmail",
        localStorage.getItem("login_email") !== "undefined" &&
          socialLoginType !== "phone"
          ? localStorage.getItem("login_email")
          : "",
      );
      setFieldValue(
        "signupFirstName",
        localStorage.getItem("login_name") !== "undefined" &&
          localStorage.getItem("login_name") !== "null"
          ? localStorage.getItem("login_name")
          : "",
      );
      setFieldValue(
        "signupPhone",
        localStorage.getItem(LOGIN_PHONE) !== "undefined" &&
          localStorage.getItem(LOGIN_PHONE) !== ""
          ? localStorage.getItem(LOGIN_PHONE)
          : "",
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsSubmitClick(false);
  }, []);

  return (
    <div
      className={isDim ? "dim-div create-account-form" : "create-account-form"}
    >
      <Grid container>
        <Grid item={true} md={2}>
          <label>
            {t("travelServices.travel_services_prefix") !==
            "travelServices.travel_services_prefix"
              ? t("travelServices.travel_services_prefix") + " *"
              : "Title *"}
          </label>
          <select
            className="selectBox selectBoxFullWidthSignUpMobile"
            disabled={val?.gender || date ? true : false}
            name="signupPrefix"
            value={values.signupPrefix}
            onChange={(e) => setFieldValue("signupPrefix", e.target.value)}
          >
            <option value="Mr">Mr.</option>
            <option value="Mrs">Mrs.</option>
            <option value="Ms">Ms.</option>
          </select>
        </Grid>
        <MediaQuery minDeviceWidth={780}>
          <Grid item={true} xs={12} sm={12} md={5} className="first_name">
            <label>
              {t("travelServices.travel_services_customer_name") !==
              "travelServices.travel_services_customer_name"
                ? t("travelServices.travel_services_customer_name")
                : "First Name + Middle Name *"}
            </label>
            <input
              className={
                touched.signupFirstName && errors.signupFirstName
                  ? "error-textbox"
                  : ""
              }
              disabled={val?.firstName || date ? true : false}
              name="signupFirstName"
              value={values.signupFirstName ? values.signupFirstName : ""}
              onChange={(e) => setFieldValue("signupFirstName", e.target.value)}
              data-lpignore="true"
              required
            />
            {touched.signupFirstName && errors.signupFirstName && (
              <p className="signup-error">{errors.signupFirstName}</p>
            )}
          </Grid>
        </MediaQuery>
        <MediaQuery maxWidth={780}>
          <Grid
            item={true}
            xs={8.5}
            sm={8.5}
            md={5}
            className="first_name"
            style={{
              marginLeft: "2px",
            }}
          >
            <label>
              {t("travelServices.travel_services_customer_name") !==
              "travelServices.travel_services_customer_name"
                ? t("travelServices.travel_services_customer_name")
                : "First Name + Middle Name *"}
            </label>
            <input
              className={
                touched.signupFirstName && errors.signupFirstName
                  ? "error-textbox"
                  : ""
              }
              disabled={val?.firstName || date ? true : false}
              name="signupFirstName"
              value={values.signupFirstName ? values.signupFirstName : ""}
              onChange={(e) => setFieldValue("signupFirstName", e.target.value)}
              data-lpignore="true"
              required
            />
            {touched.signupFirstName && errors.signupFirstName && (
              <p className="signup-error">{errors.signupFirstName}</p>
            )}
          </Grid>
        </MediaQuery>
        <Grid item={true} xs={12} sm={12} md={5} className="last_name">
          <label>
            {t("travelServices.travel_services_customer_last_name") !==
            "travelServices.travel_services_customer_last_name"
              ? t("travelServices.travel_services_customer_last_name")
              : "Last Name *"}
          </label>
          <input
            className={
              touched.signupLastName && errors.signupLastName
                ? "error-textbox"
                : ""
            }
            name="signupLastName"
            disabled={val?.lastName || date ? true : false}
            value={values.signupLastName}
            onChange={(e) => setFieldValue("signupLastName", e.target.value)}
            data-lpignore="true"
            required
          />
          {touched.signupLastName && errors.signupLastName && (
            <p className="signup-error">{errors.signupLastName}</p>
          )}
        </Grid>

        {pageName !== "addressBook" && pageName !== "profile" && (
          <>
            <Grid item xs={4} sm={3} md={3} className="phone-container">
              <label>
                {t("travelServices.travel_services_customer_phone") !==
                "travelServices.travel_services_customer_phone"
                  ? t("travelServices.travel_services_customer_phone")
                  : "Phone *"}
              </label>
              <PhonePrefix
                val={val}
                name="signupPhonePrefix"
                value={
                  sessionStorage.getItem(LOGIN_VIA_KBZPAY) &&
                  JSON.parse(sessionStorage.getItem(LOGIN_VIA_KBZPAY))?.phone
                    ? sessionStorage.getItem(LOGIN_VIA_KBZPAY) &&
                      JSON.parse(sessionStorage.getItem(LOGIN_VIA_KBZPAY))
                        ?.phone
                    : values.signupPhonePrefix || ""
                }
                prefixValue={values.signupPhonePrefix}
                handlePhonePrefix={(value) =>
                  setFieldValue("signupPhonePrefix", value)
                }
                handlePhoneFlag={(value) =>
                  setFieldValue("mobileCountryFlag", value)
                }
                mobileCountryFlag={values.mobileCountryFlag}
                isDisable={
                  socialLoginType === "phone" ||
                  (localStorage.getItem(IDENTIFIER) &&
                    localStorage.getItem(IDENTIFIER) !== "" &&
                    localStorage.getItem(IDENTIFIER).includes("+"))
                }
                showSpanLabel={false}
                dropdownStyle={{
                  background: "white",
                  border: "none",
                }}
              />
            </Grid>
            <Grid item={true} xs={8} sm={9} md={9}>
              <label>&nbsp;</label>
              <input
                className={
                  touched.signupPhone && errors.signupPhone
                    ? "error-textbox signup-box-phone"
                    : "signup-box-phone"
                }
                disabled={
                  (val !== undefined && val?.mobileNo) || date
                    ? true
                    : socialLoginType === "phone"
                }
                name="signupPhone"
                value={
                  sessionStorage.getItem(LOGIN_VIA_KBZPAY) &&
                  JSON.parse(sessionStorage.getItem(LOGIN_VIA_KBZPAY))?.user
                    ?.arranger?.phone
                    ? sessionStorage.getItem(LOGIN_VIA_KBZPAY) &&
                      JSON.parse(sessionStorage.getItem(LOGIN_VIA_KBZPAY))?.user
                        ?.arranger?.phone
                    : localStorage.getItem(LOGIN_PHONE) !== "undefined" &&
                      localStorage.getItem(LOGIN_PHONE) !== ""
                    ? localStorage.getItem(LOGIN_PHONE)
                    : values.signupPhone
                }
                onChange={(e) => setFieldValue("signupPhone", e.target.value)}
                data-lpignore="true"
                required
              />
              {touched.signupPhone && errors.signupPhone && (
                <p className="signup-error">{errors.signupPhone}</p>
              )}
            </Grid>
          </>
        )}

        {pageName !== "addressBook" && pageName !== "profile" && (
          <Grid item={true} xs={12} sm={12} md={12} className="email_input">
            <label>
              {t("travelServices.travel_services_customer_email") !==
              "travelServices.travel_services_customer_email"
                ? t("travelServices.travel_services_customer_email")
                : "Email *"}
            </label>
            <input
              className={
                (touched.signupEmail && errors.signupEmail) || formError !== ""
                  ? "error-textbox"
                  : ""
              }
              disabled={
                val?.email
                  ? true
                  : pageName === "addressBook"
                  ? false
                  : socialLoginType === "google" ||
                    pageName === "profile" ||
                    (localStorage.getItem(IDENTIFIER) &&
                      localStorage.getItem(IDENTIFIER) !== "" &&
                      localStorage.getItem(IDENTIFIER) !== "undefined" &&
                      !localStorage.getItem(IDENTIFIER).includes("+"))
                  ? true
                  : false
              }
              name="signupEmail"
              value={
                localStorage.getItem(IDENTIFIER) &&
                localStorage.getItem(IDENTIFIER) !== "undefined" &&
                socialLoginType !== "phone"
                  ? localStorage.getItem(IDENTIFIER)
                  : values.signupEmail
                  ? values.signupEmail
                  : ""
              }
              onChange={(e) => {
                setFormError("");
                setFieldValue("signupEmail", e.target.value);
              }}
              data-lpignore="true"
              required
            />
            {touched.signupEmail && errors.signupEmail && (
              <p className="signup-error">{errors.signupEmail}</p>
            )}
            {formError !== "" && <p className="signup-error">{formError}</p>}
          </Grid>
        )}

        {!isSocialLogin &&
          pageName !== "profile" &&
          pageName !== "addressBook" &&
          pageName !== "emailLinkAuth" && (
            <>
              <Grid item={true} xs={12} sm={12} md={12} className="password">
                <label>
                  {t("masterPassengerInformationTwo.password") !==
                  "masterPassengerInformationTwo.password"
                    ? t("masterPassengerInformationTwo.password")
                    : "Password"}
                </label>
                <input
                  className={
                    touched.signupPassword && errors.signupPassword
                      ? "error-textbox"
                      : ""
                  }
                  name="signupPassword"
                  type="password"
                  value={values.signupPassword}
                  autoComplete="off"
                  onChange={(e) =>
                    setFieldValue("signupPassword", e.target.value)
                  }
                  data-lpignore="true"
                  required={isSocialLogin ? false : true}
                />
                {touched.signupPassword && errors.signupPassword && (
                  <p className="signup-error">{errors.signupPassword}</p>
                )}
              </Grid>
            </>
          )}

        {campaignId !== "" && (
          <Grid item={true} sm={12} md={12}>
            <label>Camapign ID</label>
            <input
              className={
                touched.signupPassword && errors.signupPassword
                  ? "error-textbox"
                  : ""
              }
              style={{ color: "grey" }}
              name="signupPassword"
              value={campaignId}
              autoComplete="off"
              readOnly
              data-lpignore="true"
            />
          </Grid>
        )}

        {pageName === "addressBook" && (
          <>
            <Grid
              item={true}
              xs={12}
              sm={12}
              md={12}
              className="dobRegion addressBookDob"
            >
              <label>
                {t("masterPassengerInformation.dob") !==
                "masterPassengerInformation.dob"
                  ? t("masterPassengerInformation.dob") + " *"
                  : "Date of Birth *"}
              </label>
              <Grid container className="dobSelects">
                <Grid
                  item={true}
                  md={3}
                  style={{
                    width: "70px",
                  }}
                >
                  <select
                    name="signupDobDay"
                    className={
                      values.signupDobDay === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    required
                    disabled={date ? true : false}
                    value={values.signupDobDay}
                    onChange={(e) =>
                      setFieldValue("signupDobDay", e.target.value)
                    }
                  >
                    <DayOptionComponent
                      dayOption={values.signupDobMonth === "02" ? 29 : 31}
                    />
                  </select>
                  {isSubmitClick && values.signupDobDay === "00" && (
                    <p className="signup-error">Birth day is required</p>
                  )}
                </Grid>
                <Grid
                  item={true}
                  md={5}
                  style={{
                    width: "110px",
                  }}
                >
                  <select
                    name="signupDobMonth"
                    disabled={date ? true : false}
                    className={
                      values.signupDobMonth === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    required
                    value={values.signupDobMonth}
                    onChange={(e) =>
                      setFieldValue("signupDobMonth", e.target.value)
                    }
                  >
                    <MonthOptionComponent />
                  </select>
                  {isSubmitClick && values.signupDobMonth === "00" && (
                    <p className="signup-error">Birth month is required.</p>
                  )}
                </Grid>
                <Grid
                  item={true}
                  md={4}
                  style={{
                    width: "90px",
                  }}
                >
                  <select
                    disabled={date}
                    required
                    name="signupDobYear"
                    className={
                      values.signupDobYear === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    value={values.signupDobYear}
                    onChange={(e) =>
                      setFieldValue("signupDobYear", e.target.value)
                    }
                  >
                    <YearOptionComponent type="dob" />
                  </select>
                  {isSubmitClick && values.signupDobYear === "0000" && (
                    <p className="signup-error">Birth year is required.</p>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} sm={12} md={12}>
              <label>
                {t(`b2c.nationality`) !== "b2c.nationality"
                  ? t(`b2c.nationality`) + " *"
                  : "Nationality *"}
              </label>
              <select
                className="nationality"
                name="signupNationality"
                disabled={date}
                value={values.signupNationality}
                onChange={(e) =>
                  setFieldValue("signupNationality", e.target.value)
                }
              >
                {COUNTRIES.map((country, i) => {
                  return (
                    <option key={i} value={country.CountryId}>
                      {country.CountryName}
                    </option>
                  );
                })}
              </select>
            </Grid>
            {Number(values.signupNationality) === MYANMAR_ID && (
              <Grid
                item={true}
                xs={12}
                sm={12}
                md={12}
                className="nrcRegion addressBookNrc"
              >
                <label>
                  {t(`b2c.nrc`) !== "b2c.nrc" ? t(`b2c.nrc`) + " *" : "NRC *"}
                </label>
                <Grid container className="nrcSelects">
                  <Grid item={true} xs={3} sm={3} md={2}>
                    <select
                      name="nrcDivision"
                      disabled={date}
                      defaultValue={5}
                      className="selectBox nrcNumberField"
                      value={selectedDivision}
                      onChange={(e) => {
                        setSelectedDivison(e.target.value);
                        setDefaultList(e.target.value);
                        chooseList(e.target.value);
                      }}
                    >
                      {DivisionList.map((division, key) => {
                        return (
                          <option key={key} value={division}>
                            {division}
                          </option>
                        );
                      })}
                    </select>
                  </Grid>
                  <Grid item={true} xs={5} sm={5} md={4}>
                    <select
                      name="nrcList"
                      disabled={date}
                      className="selectBox nrcTextField"
                      value={selectedList}
                      onChange={(e) => {
                        setSelectedList(e.target.value);
                        handleNrc(
                          selectedDivision,
                          e.target.value,
                          selectedCardList,
                          selectedNrcNo,
                        );
                      }}
                    >
                      {listArr &&
                        listArr.map((list, key) => {
                          return (
                            <option key={key} value={list}>
                              {list}
                            </option>
                          );
                        })}
                    </select>
                  </Grid>
                  <Grid item={true} xs={4} sm={4} md={2}>
                    <select
                      disabled={date}
                      name="nrcCard"
                      className="selectBox nrcNumberField"
                      value={selectedCardList}
                      onChange={(e) => {
                        setSelectedCardList(e.target.value);
                        handleNrc(
                          selectedDivision,
                          selectedList,
                          e.target.value,
                          selectedNrcNo,
                        );
                      }}
                    >
                      {CardList.map((card, key) => {
                        return (
                          <option key={key} value={card}>
                            {card}
                          </option>
                        );
                      })}
                    </select>
                  </Grid>
                  <Grid item={true} xs={12} sm={12} md={4}>
                    <input
                      name="nrcNumber"
                      disabled={date}
                      className="nrcNumber"
                      value={selectedNrcNo}
                      autoComplete="off"
                      onChange={(e) => {
                        if (!/^[0-9\b]*$/.test(e.target.value)) {
                          setCheckNRC(true);
                          return;
                        }
                        setCheckNRC(false);
                        setSelectedNrcNo(e.target.value);
                        handleNrc(
                          selectedDivision,
                          selectedList,
                          selectedCardList,
                          e.target.value,
                        );
                      }}
                      data-lpignore="true"
                    />
                    {checkNRC && (
                      <p className="signup-error">Please type only number.</p>
                    )}
                    {isSubmitClick && selectedNrcNo === "" && (
                      <p className="signup-error">NRC is required.</p>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </>
        )}
        {pageName === "addressBook" && (
          <>
            <Grid
              item={true}
              xs={12}
              sm={6}
              md={6}
              className="nrcRegion addressBookNrc"
            >
              <label>
                {t(`b2c.passport`) !== "b2c.passport"
                  ? t(`b2c.passport`) + " *"
                  : "Passport"}
              </label>
              <Grid item={true} sm={12} md={12}>
                <input
                  className={
                    touched.signupNrcPass && errors.signupNrcPass
                      ? "error-textbox nrcNumber"
                      : "nrcNumber"
                  }
                  name="signupNrcPass"
                  disabled={date}
                  type="text"
                  value={values.signupNrcPass}
                  autoComplete="off"
                  onChange={(e) =>
                    setFieldValue("signupNrcPass", e.target.value)
                  }
                  data-lpignore="true"
                  required
                />
                {touched.signupNrcPass && errors.signupNrcPass && (
                  <p className="signup-error">{errors.signupNrcPass}</p>
                )}
              </Grid>
            </Grid>
            <Grid
              item={true}
              sm={6}
              md={6}
              className="nrcRegion addressBookNrc"
            >
              <label>
                {t(`b2c.passport_expiration_date`) !==
                "b2c.passport_expiration_date"
                  ? `${t(`b2c.passport_expiration_date`)} *`
                  : "Passport Expiration Date"}
              </label>
              <Grid container className="passSelects">
                <Grid
                  item={true}
                  md={3}
                  style={{
                    width: "70px",
                  }}
                >
                  <select
                    disabled={date}
                    name="signupExpDay"
                    className={
                      values.signupExpDay === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    value={values.signupExpDay}
                    onChange={(e) =>
                      setFieldValue("signupExpDay", e.target.value)
                    }
                  >
                    <DayOptionComponent
                      dayOption={values.signupExpMonth === "02" ? 29 : 31}
                    />
                    {/* {passExpErr?.error && (
                            <p className="signup-error">
                              {passExpErr?.errorMessage}
                            </p>
                          )} */}
                  </select>
                  {touched.signupExpDay && errors.signupExpDay && (
                    <p className="signup-error">{errors.signupExpDay}</p>
                  )}
                </Grid>
                <Grid
                  item={true}
                  md={5}
                  style={{
                    width: "110px",
                  }}
                >
                  <select
                    name="signupExpMonth"
                    disabled={date}
                    className={
                      values.signupExpMonth === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    value={values.signupExpMonth}
                    onChange={(e) =>
                      setFieldValue("signupExpMonth", e.target.value)
                    }
                  >
                    <MonthOptionComponent />
                  </select>
                  {touched.signupExpMonth && errors.signupExpMonth && (
                    <p className="signup-error">{errors.signupExpMonth}</p>
                  )}
                </Grid>
                <Grid
                  item={true}
                  md={4}
                  style={{
                    width: "90px",
                  }}
                >
                  <select
                    name="signupExpYear"
                    disabled={date}
                    className={
                      values.signupExpYear === "00"
                        ? "selectBox placeHolderText"
                        : "selectBox"
                    }
                    value={values.signupExpYear}
                    onChange={(e) =>
                      setFieldValue("signupExpYear", e.target.value)
                    }
                  >
                    <YearOptionComponent type="exp" />
                  </select>
                  {touched.signupExpYear && errors.signupExpYear && (
                    <p className="signup-error">{errors.signupExpYear}</p>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        {/* {!isSocialLogin && (
          <Grid item={true} xs={12} sm={12} md={6}>
            <label>Confirm Password *</label>
            <input
              className={
                touched.signupConfirmPassword && errors.signupConfirmPassword
                  ? "error-textbox"
                  : ""
              }
              name="signupConfirmPassword"
              type="password"
              value={values.signupConfirmPassword}
              autoComplete="off"
              onChange={e =>
                setFieldValue("signupConfirmPassword", e.target.value)
              }
              data-lpignore="true"
              required={isSocialLogin ? false : true}
            />
            {touched.signupConfirmPassword && errors.signupConfirmPassword && (
              <p className="signup-error">{errors.signupConfirmPassword}</p>
            )}
          </Grid>
        )} */}
        {pageName === "profile" && (
          <ProfileEditForm
            values={values}
            date={date}
            setFieldValue={setFieldValue}
            selectedDivision={selectedDivision}
            setSelectedDivison={setSelectedDivison}
            setDefaultList={setDefaultList}
            chooseList={chooseList}
            selectedList={selectedList}
            setSelectedList={setSelectedList}
            handleNrc={handleNrc}
            selectedNrcNo={selectedNrcNo}
            selectedCardList={selectedCardList}
            setSelectedCardList={setSelectedCardList}
            checkNRC={checkNRC}
            setCheckNRC={setCheckNRC}
            setSelectedNrcNo={setSelectedNrcNo}
            DivisionList={DivisionList}
            listArr={listArr}
            CardList={CardList}
            touched={touched}
            errors={errors}
            getCity={getCity}
            getTownship={getTownship}
            regionArr={regionArr}
            cityArr={cityArr}
            townshipArr={townshipArr}
          />
        )}

        {pageName !== "profile" && (
          <Grid item={true} xs={12} sm={12} md={12} className="signup-more-div">
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <p style={{ color: "black" }}>
                  {t(`b2c.more_info_optional`) !== "b2c.more_info_optional"
                    ? t(`b2c.more_info_optional`)
                    : "More Information (Optional)"}
                </p>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {pageName !== "addressBook" && (
                    <Grid
                      item={true}
                      xs={12}
                      sm={12}
                      md={6}
                      className="dobRegion"
                    >
                      <label>
                        {t("masterPassengerInformation.dob") !==
                        "masterPassengerInformation.dob"
                          ? t("masterPassengerInformation.dob") + " *"
                          : "Date of Birth *"}
                      </label>
                      <div container className="dobSelects">
                        <Grid item={true} xs={4} sm={4} md={3}>
                          <select
                            name="signupDobDay"
                            className={
                              values.signupDobDay === "00"
                                ? "selectBox placeHolderText"
                                : "selectBox"
                            }
                            disabled={date ? true : false}
                            value={values.signupDobDay}
                            onChange={(e) =>
                              setFieldValue("signupDobDay", e.target.value)
                            }
                          >
                            <DayOptionComponent
                              dayOption={
                                values.signupDobMonth === "02" ? 29 : 31
                              }
                            />
                          </select>
                        </Grid>
                        <Grid item={true} xs={4} sm={4} md={5}>
                          <select
                            name="signupDobMonth"
                            disabled={date ? true : false}
                            className={
                              values.signupDobMonth === "00"
                                ? "selectBox placeHolderText"
                                : "selectBox"
                            }
                            value={values.signupDobMonth}
                            onChange={(e) =>
                              setFieldValue("signupDobMonth", e.target.value)
                            }
                          >
                            <MonthOptionComponent />
                          </select>
                        </Grid>
                        <Grid item={true} xs={4} sm={4} md={4}>
                          <select
                            disabled={date}
                            name="signupDobYear"
                            className={
                              values.signupDobYear === "00"
                                ? "selectBox placeHolderText"
                                : "selectBox"
                            }
                            value={values.signupDobYear}
                            onChange={(e) =>
                              setFieldValue("signupDobYear", e.target.value)
                            }
                          >
                            <YearOptionComponent type="dob" />
                          </select>
                        </Grid>
                      </div>
                    </Grid>
                  )}

                  {pageName !== "addressBook" && (
                    <Grid
                      item={true}
                      sm={12}
                      md={pageName === "addressBook" ? 8 : 6}
                      className="nationality-grid"
                    >
                      <label>
                        {t(`b2c.nationality`) !== "b2c.nationality"
                          ? t(`b2c.nationality`) + " *"
                          : "Nationality *"}
                      </label>
                      <select
                        className="nationality"
                        name="signupNationality"
                        disabled={date}
                        value={values.signupNationality}
                        onChange={(e) =>
                          setFieldValue("signupNationality", e.target.value)
                        }
                      >
                        {COUNTRIES.map((country, i) => {
                          return (
                            <option key={i} value={country.CountryId}>
                              {country.CountryName}
                            </option>
                          );
                        })}
                      </select>
                    </Grid>
                  )}
                  {Number(values.signupNationality) === MYANMAR_ID &&
                    pageName !== "addressBook" && (
                      <Grid
                        item={true}
                        xs={12}
                        sm={12}
                        md={12}
                        className="nrcRegion"
                      >
                        <label>
                          {t(`b2c.nrc`) !== "b2c.nrc" ? t(`b2c.nrc`) : "NRC"}
                        </label>
                        <div container className="nrcSelects">
                          <Grid item={true} xs={3} sm={3} md={1}>
                            <select
                              name="nrcDivision"
                              disabled={date}
                              defaultValue={5}
                              className="selectBox nrcNumberField"
                              value={selectedDivision}
                              onChange={(e) => {
                                setSelectedDivison(e.target.value);
                                setDefaultList(e.target.value);
                                chooseList(e.target.value);
                              }}
                            >
                              {DivisionList.map((division, key) => {
                                return (
                                  <option key={key} value={division}>
                                    {division}
                                  </option>
                                );
                              })}
                            </select>
                          </Grid>
                          <Grid item={true} xs={5} sm={5} md={4}>
                            <select
                              name="nrcList"
                              disabled={date}
                              className="selectBox nrcTextField"
                              value={selectedList}
                              onChange={(e) => {
                                setSelectedList(e.target.value);
                                handleNrc(
                                  selectedDivision,
                                  e.target.value,
                                  selectedCardList,
                                  selectedNrcNo,
                                );
                              }}
                            >
                              {listArr &&
                                listArr.map((list, key) => {
                                  return (
                                    <option key={key} value={list}>
                                      {list}
                                    </option>
                                  );
                                })}
                            </select>
                          </Grid>
                          <Grid item={true} xs={4} sm={4} md={1}>
                            <select
                              disabled={date}
                              name="nrcCard"
                              className="selectBox nrcNumberField"
                              value={selectedCardList}
                              onChange={(e) => {
                                setSelectedCardList(e.target.value);
                                handleNrc(
                                  selectedDivision,
                                  selectedList,
                                  e.target.value,
                                  selectedNrcNo,
                                );
                              }}
                            >
                              {CardList.map((card, key) => {
                                return (
                                  <option key={key} value={card}>
                                    {card}
                                  </option>
                                );
                              })}
                            </select>
                          </Grid>
                          <Grid item={true} xs={12} sm={12} md={6}>
                            <input
                              name="nrcNumber"
                              disabled={date}
                              className="nrcNumber"
                              value={selectedNrcNo}
                              autoComplete="off"
                              onChange={(e) => {
                                if (!/^[0-9\b]*$/.test(e.target.value)) {
                                  setCheckNRC(true);
                                  return;
                                }
                                setCheckNRC(false);
                                setSelectedNrcNo(e.target.value);
                                handleNrc(
                                  selectedDivision,
                                  selectedList,
                                  selectedCardList,
                                  e.target.value,
                                );
                              }}
                              data-lpignore="true"
                            />
                            {checkNRC && (
                              <p className="signup-error">
                                Please type only number.
                              </p>
                            )}
                          </Grid>
                        </div>
                      </Grid>
                    )}
                  {/* {Number(values.signupNationality) !== MYANMAR_ID && ( */}
                  {pageName !== "addressBook" && (
                    <>
                      <Grid
                        item={true}
                        xs={12}
                        sm={6}
                        md={6}
                        className="nrcRegion"
                      >
                        <label>
                          {t(`b2c.passport`) !== "b2c.passport"
                            ? t(`b2c.passport`)
                            : "Passport"}
                        </label>
                        <Grid item={true} sm={12} md={12}>
                          <input
                            className={
                              touched.signupNrcPass && errors.signupNrcPass
                                ? "error-textbox passNumber"
                                : "passNumber"
                            }
                            name="signupNrcPass"
                            disabled={date}
                            type="text"
                            value={values.signupNrcPass}
                            autoComplete="off"
                            onChange={(e) =>
                              setFieldValue("signupNrcPass", e.target.value)
                            }
                            data-lpignore="true"
                            required
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item={true}
                        xs={12}
                        sm={12}
                        md={6}
                        className="nrcRegion"
                      >
                        <label>
                          {t(`b2c.passport_expiration_date`) !==
                          "b2c.passport_expiration_date"
                            ? t(`b2c.passport_expiration_date`)
                            : "Passport Expiration Date"}
                        </label>
                        <Grid container className="passSelects">
                          <Grid item={true} xs={4} sm={4} md={3}>
                            <select
                              disabled={date}
                              name="signupExpDay"
                              className={
                                values.signupExpDay === "00"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              value={values.signupExpDay}
                              onChange={(e) =>
                                setFieldValue("signupExpDay", e.target.value)
                              }
                            >
                              <DayOptionComponent
                                dayOption={
                                  values.signupExpMonth === "02" ? 29 : 31
                                }
                              />
                            </select>
                          </Grid>
                          <Grid item={true} xs={4} sm={4} md={5}>
                            <select
                              name="signupExpMonth"
                              disabled={date}
                              className={
                                values.signupExpMonth === "00"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              value={values.signupExpMonth}
                              onChange={(e) =>
                                setFieldValue("signupExpMonth", e.target.value)
                              }
                            >
                              <MonthOptionComponent />
                            </select>
                          </Grid>
                          <Grid item={true} xs={4} sm={4} md={4}>
                            <select
                              name="signupExpYear"
                              disabled={date}
                              className={
                                values.signupExpYear === "00"
                                  ? "selectBox placeHolderText"
                                  : "selectBox"
                              }
                              value={values.signupExpYear}
                              onChange={(e) =>
                                setFieldValue("signupExpYear", e.target.value)
                              }
                            >
                              <YearOptionComponent type="exp" />
                            </select>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {Number(values.signupNationality) === MYANMAR_ID && (
                    <>
                      <Grid item={true} xs={6} sm={6} md={4}>
                        <label>
                          {t(`b2c.region`) !== "b2c.region"
                            ? t(`b2c.region`)
                            : "Region"}
                        </label>
                        <select
                          name="region"
                          disabled={date}
                          className="selectBox regionSelect"
                          value={values.region}
                          onChange={(e) => {
                            setFieldValue("region", e.target.value);
                            setFieldValue("cityArr", []);
                            getCity({
                              variables: {
                                first: 30,
                                page: 1,
                                filter: [
                                  {
                                    field: "region_id",
                                    operator: "=",
                                    value: e.target.value,
                                  },
                                ],
                              },
                            });
                          }}
                        >
                          {regionArr.map((val, key) => {
                            return (
                              <option key={key} value={val.value}>
                                {val.label}
                              </option>
                            );
                          })}
                        </select>
                      </Grid>

                      <Grid item={true} xs={6} sm={6} md={4}>
                        <label>
                          {t(`b2c.city`) !== "b2c.city"
                            ? t(`b2c.city`)
                            : "City"}
                        </label>
                        <select
                          name="city"
                          disabled={date}
                          className="selectBox regionSelect"
                          value={values.city}
                          onChange={(e) => {
                            setFieldValue("city", e.target.value);
                            setFieldValue("townshipArr", []);
                            getTownship({
                              variables: {
                                first: 30,
                                page: 1,
                                filter: [
                                  {
                                    field: "city_id",
                                    operator: "=",
                                    value: e.target.value,
                                  },
                                ],
                              },
                            });
                          }}
                        >
                          <option value="">Select City</option>
                          {cityArr.map((val, key) => {
                            return (
                              <option key={key} value={val.value}>
                                {val.label}
                              </option>
                            );
                          })}
                        </select>
                      </Grid>

                      <Grid item={true} xs={12} sm={12} md={4}>
                        <label>
                          {t(`b2c.township`) !== "b2c.township"
                            ? t(`b2c.township`)
                            : "Township"}
                        </label>
                        <select
                          disabled={date}
                          name="township"
                          className="selectBox regionSelect"
                          value={values.township}
                          onChange={(e) =>
                            setFieldValue("township", e.target.value)
                          }
                        >
                          <option value="">Select Township</option>
                          {townshipArr.map((val, key) => {
                            return (
                              <option key={key} value={val.value}>
                                {val.label}
                              </option>
                            );
                          })}
                        </select>
                      </Grid>
                    </>
                  )}
                  <Grid item={true} xs={12} sm={12} md={12}>
                    <label>
                      {t(`b2c.address_details`) !== "b2c.address_details"
                        ? t(`b2c.address_details`)
                        : "Address Details"}
                    </label>
                    <textarea
                      name="address"
                      disabled={date}
                      className="address"
                      value={values.address}
                      autoComplete="off"
                      onChange={(e) => setFieldValue("address", e.target.value)}
                    ></textarea>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
      </Grid>
      {/* {pageName === "profile" && date ? (
        <p style={{ textAlign: "center", color: "red" }}>
          You have to wait 7 days for next attempt.
        </p>
      ) : (
        ""
      )} */}
      <p style={{ textAlign: "center" }}>{errorMsg}</p>
      {addId === "addressBookCreate" || addId === "addressBookEdit" ? (
        <div className="addreBookBtn">
          <button
            disabled={checkNRC ? checkNRC : date}
            onClick={() => {
              setIsShow && setIsShow(false);
            }}
            style={{
              color: "#04adef",
              backgroundColor: "white",
              marginRight: 10,
              width: 100,
            }}
          >
            {"Cancel"}
          </button>
          <button
            disabled={checkNRC ? checkNRC : date}
            onClick={() => {
              setIsSubmitClick(true);
              handleSubmit();
            }}
            style={{
              backgroundColor: "#04adef",
              color: "white",
              width: 100,
            }}
          >
            {"Save"}
            {isSpinner && <CircularProgress />}
          </button>
        </div>
      ) : (
        <Button
          color="primary"
          variant="contained"
          disabled={checkNRC ? checkNRC : date}
          type="button"
          className="signup-btn"
          id="btn-sign-in"
          onClick={() => {
            setIsSubmitClick(true);
            handleSubmit();
          }}
        >
          {pageName === "profile"
            ? "Save"
            : t("masterPassengerInformationTwo.sign_up") !==
              "masterPassengerInformationTwo.sign_up"
            ? t("masterPassengerInformationTwo.sign_up")
            : "Sign Up"}{" "}
          {isSpinner && <CircularProgress />}
        </Button>
      )}
    </div>
  );
};

export default compose(
  withFormik({
    mapPropsToValues: (props) => {
      let val =
        props.regValues &&
        props.regValues.adultInfo &&
        props.regValues.adultInfo.length > 0
          ? props.regValues.adultInfo[0]
          : null;

      return {
        id:
          localStorage.getItem("arranger_people_id") &&
          localStorage.getItem("arranger_people_id") !== ""
            ? localStorage.getItem("arranger_people_id")
            : props?.addId === "addressBookEdit"
            ? props?.data?.id
            : null,
        signupPrefix: val && val.title ? val.title : "Mr",
        signupFirstName: val && val.firstName ? val.firstName : "",
        signupLastName: val && val.lastName ? val.lastName : "",
        signupEmail:
          val && val.email && !OTP_LOGIN_EMAIL_REGEX.test(val.email)
            ? val.email
            : "",
        signupPassword: "",
        mobileCountryFlag:
          val && val.mobileCountryFlag ? val.mobileCountryFlag : "mm",
        signupPhonePrefix: val && val.mobilePrefix ? val.mobilePrefix : "95",
        signupPhone: val && val.mobileNo ? val.mobileNo : "",
        signupDobDay: val && val.dobDay ? val.dobDay : "00",
        signupDobMonth: val && val.dobMonth ? val.dobMonth : "00",
        signupDobYear: val && val.dobYear ? val.dobYear : "0000",
        signupExpDay: val && val.expDay ? val.expDay : "00",
        signupExpMonth: val && val.expMonth ? val.expMonth : "00",
        signupExpYear: val && val.expYear ? val.expYear : "0000",
        signupNationality: MYANMAR_ID,
        signupNrcPass: val && val.passportNrc ? val.passportNrc : "",
        signupNrc: val && val.nrc ? val.nrc : "",
        region: val && val.regionId ? val.regionId : YANGON_REGION,
        city: val && val.cityId ? val.cityId : YANGON_CITY,
        township: val && val.townshipId ? val.townshipId : null,
        address: val && val.address ? val.address : "",
      };
    },
    validationSchema: (props) => ValidationMethod(props),
    handleSubmit(values, { props }) {
      props.setIsSpinner(true);
      props.handleSignUp(values);
    },
  }),
)(SignupForm);
